import { Component, Input, OnInit } from '@angular/core';
import { AddressbookRecord, CallHistoryRecord, PRESENCE_STATUS, SIP_STATUS } from '../../../core/services';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  public _statusClass: string = 'none';
  public _image: string = null;
  public _backgroundColor: string = '#000000';

  public _isIntegrationContact: boolean = false;
  public _contactName: string;
  public _integrationName: string;
  public _integrationLogo: string;

  
  public _contact: AddressbookRecord | CallHistoryRecord | string;

  @Input() set contact(v: AddressbookRecord | CallHistoryRecord) {
    this._contact = v;
  }

  @Input() type: string;

  @Input() shape: string = 'circle';

  @Input() set backgroundColor(v: string) {
    if(typeof v === 'string' && v.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)){
      this._backgroundColor = v;
    } else {
      this._backgroundColor = '#000000'
    }
  }


  @Input() set status(s: PRESENCE_STATUS | SIP_STATUS) {
    switch (s) {
      case SIP_STATUS.ONLINE:
      case SIP_STATUS.OFFLINE:
      case SIP_STATUS.DND:
      case PRESENCE_STATUS.ONLINE:
      case PRESENCE_STATUS.OFFLINE:
      case PRESENCE_STATUS.DND:
      case PRESENCE_STATUS.AVAILABLE:
      case PRESENCE_STATUS.RINGING:
        this._statusClass = s;
        break;
      case PRESENCE_STATUS.ON_THE_PHONE:
        this._statusClass = 'on-the-phone';
        break;
      case PRESENCE_STATUS.NONE:
        this._statusClass = 'd-none';
        break;
      default:
        this._statusClass = 'd-none';
    }
  }

  constructor() { }

  ngOnInit(): void {
    if(this._contact && this.type) {
      switch(this.type) {
        case 'addressbook':
          this._contactName = this._contact['lastName'] ? `${this._contact['firstName']} ${this._contact['lastName']}` : this._contact['firstName'];
          if(this._contact['CRM'] && this._contact['CRM'] !== 'Voxloud') {
            this._isIntegrationContact = true;
            this._integrationName = this._contact['CRM'];
            this._integrationLogo = this._contact['crm_logo'];
          }
          break;
        case 'history':
          this._contactName = this._contact['displayName']['name']['remote'];
          if(this._contact['crmName'] && this._contact['crmName'] !== 'Voxloud') {
            this._isIntegrationContact = true;
            this._integrationName = this._contact['crmName'];
            this._integrationLogo = this._contact['crmLogo'];
          }
          break;
        case 'string':
          this._contactName = this._contact as string;
          break;
      }
    }
  }
}
